import {
    DRAWER_STORAGE,
    TAB_MENU_STORAGE,
    INSTANCE_VALUE_STORAGE,
    LOCATION_VALUE_STORAGE,
    FORM_BLOCK_STORAGE,
    DATA_LOCATION_BS,
    DATA_VM_BS,
    INSTANCE_SELECTED_BS,
    DETAIL_BLOCK_STORAGE,
    RESIZE_STORAGE,
    AVAILABLE_BS
} from 'store/actions/storageAction';

export const initialState = {
    tab_menu: 'block_storage',
    drawer: { open: false, flag: '' },
    instance: undefined,
    location: undefined,
    formBlockStorage: { name: '', location: undefined, tier: undefined, size: 0 },
    dataLocation: [],
    dataVm: [],
    instanceSelected: undefined,
    detailBlockStorage: {},
    resizeStorage: '',
    availableBs: ' '
};

// ==============================|| STORAGE REDUCER ||============================== //

const storageReducer = (state = initialState, action) => {
    switch (action.type) {
        case TAB_MENU_STORAGE:
            return {
                ...state,
                tab_menu: action.payload
            };
        case DRAWER_STORAGE:
            return {
                ...state,
                drawer: action.payload
            };
        case INSTANCE_VALUE_STORAGE:
            return {
                ...state,
                instance: action.payload
            };
        case LOCATION_VALUE_STORAGE:
            return {
                ...state,
                location: action.payload
            };
        case FORM_BLOCK_STORAGE:
            return {
                ...state,
                formBlockStorage: action.payload
            };
        case DATA_LOCATION_BS:
            return {
                ...state,
                dataLocation: action.payload
            };
        case DATA_VM_BS:
            return {
                ...state,
                dataVm: action.payload
            };
        case INSTANCE_SELECTED_BS:
            return {
                ...state,
                instanceSelected: action.payload
            };
        case DETAIL_BLOCK_STORAGE:
            return {
                ...state,
                detailBlockStorage: action.payload
            };
        case RESIZE_STORAGE:
            return {
                ...state,
                resizeStorage: action.payload
            };
        case AVAILABLE_BS:
            return {
                ...state,
                availableBs: action.payload
            };

        default:
            return state;
    }
};

export default storageReducer;
